<template>
  <div class="auth-layout-wrap">
    <div class="auth-content">
      <div class="card o-hidden">
        <transition
          name="page"
          mode="out-in"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="p-4">
                <div class="auth-logo text-center mb-30">
                  <img
                    v-if="logo !== ''"
                    :src="logo"
                  >
                </div>
                <h1 class="mb-3 text-18">
                  Aanmelden Burenhulpdienst
                </h1>
                <b-form @submit.prevent="formSubmit">
                  <b-form-group
                    label="Email"
                    class="text-12"
                  >
                    <b-form-input
                      v-model="email"
                      class="form-control-rounded"
                      type="text"
                      email
                      required
                    />
                  </b-form-group>

                  <b-form-group
                    label="Wachtwoord"
                    class="text-12"
                  >
                    <b-form-input
                      v-model="password"
                      class="form-control-rounded"
                      type="password"
                    />
                  </b-form-group>

                  <!-- <b-button block to="/" variant="primary btn-rounded mt-2"
                  >Sign In</b-button
                > -->
                  <b-button
                    type="submit"
                    tag="button"
                    class="btn-rounded btn-block mt-2"
                    variant="primary mt-2"
                    :disabled="loading"
                  >
                    Aanmelden
                  </b-button>
                  <div
                    v-if="loading"
                    v-once
                    class="typo__p"
                  >
                    <div class="spinner sm spinner-primary mt-3" />
                  </div>
                </b-form>

                <div class="mt-3 text-center">
                  <router-link
                    to="forgot"
                    tag="a"
                    class="text-muted"
                  >
                    <u>Wachtwoord vergeten?</u>
                  </router-link>
                </div>
              </div>
            </div>

            <b-col
              md="6"
              class="text-center"
              style="background-repeat: no-repeat; background-position: center; backgroundsize: cover"
              :style="{ backgroundImage: 'url(' + signInImage + ')' }"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapGetters, mapActions } from 'vuex';
import { authenticationService } from "@/services";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Aanmelden Burenhulp",
  },
  data() {
    return {
      email: "",
      password: "",
      // // password: "vue006",
      userId: "",
      bgImage: require("@/assets/images/photo-wide-2.jpg"),
      logo: require("@/assets/images/logo.jpg"),
      signInImage: require("@/assets/images/photo-long-1.jpg"),
      loading: false,
    };
  },
  computed: {
    validation() {
      return this.userId.length > 4 && this.userId.length < 13;
    },
  },

  methods: {
    formSubmit() {
      this.loading = true;
      authenticationService.login(this.email, this.password).then((res) => {
        console.log(res);
        this.loading = false;
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}

.auth-logo img {
  width: auto !important;
  height: auto !important;
}
</style>
