import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { BootstrapVue, BootstrapVueIcons, ToastPlugin } from 'bootstrap-vue';
import ThemifyIcon from 'vue-themify-icons';
import VueFormWizard from 'vue-form-wizard';
import VueGoodTablePlugin from 'vue-good-table';

import App from './App.vue';
import router from './router';
import store from './store';
import '@/filters';

import DefaultLayout from './containers/layout/default/index.vue';

import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import 'vue-good-table/dist/vue-good-table.css';
import '@/assets/styles/sass/themes/lite-blue.scss';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ToastPlugin);

Vue.use(Vuelidate);
Vue.use(VueFormWizard);
Vue.use(ThemifyIcon);
Vue.use(VueGoodTablePlugin);

Vue.component('default-layout', DefaultLayout);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
