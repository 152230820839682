<template>
  <div class="main-header">
    <div class="logo-local">
      <img src="@/assets/images/logo.jpg">
    </div>
    <div class="header-part-left">
      <button
        type="button"
        class="btn btn-outline-primary ripple m-1"
        @click.prevent="showParticipants"
      >
        Deelnemers
      </button>
      <button
        type="button"
        class="btn btn-outline-primary ripple m-1"
        @click.prevent="showCarereceivers"
      >
        Hulpaanvragen
      </button>
      <button
        type="button"
        class="btn btn-outline-primary ripple m-1"
        @click.prevent="showCaregivers"
      >
        Hulpaanbod
      </button>
      <button
        type="button"
        class="btn btn-outline-primary ripple m-1"
        @click.prevent="showMatches"
      >
        Matches
      </button>
      <button
        type="button"
        class="btn btn-outline-primary ripple m-1"
        @click.prevent="showUsers"
      >
        Gebruikers
      </button>
      <button
        type="button"
        class="btn btn-outline-primary ripple m-1"
        @click.prevent="showParticipantsArchive"
      >
        Deelnemers Archief
      </button>
    </div>
    <div class="header-part-right">
      <!-- User avatar dropdown -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <img
              id="userDropdown"
              src="@/assets/images/default.png"
              alt
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
          </template>
          <div
            class="dropdown-menu-right"
            aria-labelledby="userDropdown"
          >
            <div class="dropdown-header">
              <b-icon
                icon="person"
                font-scale="1"
              /> {{ currentUser.displayName }}
            </div>
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="logoutUser"
            >Afmelden</a>
          </div>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { authenticationService } from '@/services';
import { Roles } from '@/helpers';

export default {
  data() {
    return {
      currentUser: null,
    };
  },
  computed: {
    isAdmin() {
      return this.currentUser && this.currentUser.roles.includes(Roles.Admin);
    },
  },
  created() {
    authenticationService.currentUser.subscribe((x) => { this.currentUser = x; });
  },
  methods: {
    logoutUser() {
      authenticationService.logout();
      this.$router.push('/');
    },
    showParticipants() {
      this.$router.push('/home');
    },
    showParticipantsArchive() {
      this.$router.push('/archive');
    },
    showCaregivers() {
      this.$router.push('/caregivers');
    },
    showCarereceivers() {
      this.$router.push('/carereceivers');
    },
    showMatches() {
      this.$router.push('/matches');
    },
    showUsers() {
      this.$router.push('/users');
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-local {
    width: 13%;
}

.main-header .header-part-left {
    margin-left: 280px;
}
</style>
