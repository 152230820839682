export const RequestStatus = [
  {
    value: '1',
    text: 'Open',
  },
  {
    value: '2',
    text: 'Gekoppeld',
  },
  {
    value: '3',
    text: 'Niet in te vullen',
  },
  {
    value: '4',
    text: 'Afgehandeld',
  },
  {
    value: '5',
    text: 'Niet doorgegaan',
  },
];

export const HelpTypes = [
  {
    value: '91',
    text: 'Sociaal praatje/koffie drinken',
  },
  {
    value: '92',
    text: 'Klusjes technisch',
  },
  {
    value: '93',
    text: 'Klusjes praktisch',
  },
  {
    value: '94',
    text: 'Tuinklusjes',
  },
  {
    value: '95',
    text: 'Boodschappen',
  },
  {
    value: '96',
    text: 'Vervoer divers',
  },
  {
    value: '97',
    text: 'Vervoer ziekenhuis',
  },
  {
    value: '98',
    text: 'Computer',
  },
  {
    value: '99',
    text: 'Telefoon, Ipad',
  },
  {
    value: '910',
    text: 'Administratie',
  },
  {
    value: '911',
    text: 'Wandelen',
  },
  {
    value: '912',
    text: 'Schoonmaken/opruimen',
  },
  {
    value: '913',
    text: 'Hond uitlaten',
  },
  {
    value: '914',
    text: 'Naai- en verstelwerk',
  },
  {
    value: '915',
    text: 'Informatiekraam',
  }
];

export const YesNo = [
  {
    value: 'yes',
    text: 'Ja',
  },
  {
    value: 'no',
    text: 'Nee',
  },
];

export const Duration = [
  {
    value: '1',
    text: 'Nee',
  },
  {
    value: '2',
    text: 'Ja',
  },
];

export const RolesNames = [
  {
    value: 'admin',
    text: 'Kernteamlid',
  },
  {
    value: 'contactperson',
    text: 'Contactpersoon',
  },
];

export const UserStatus = [
  { value: 'not-verified', text: 'Niet gevalideerd' },
  { value: 'verified', text: 'Gevalideerd' },
  { value: 'blocked', text: 'Geblokkeerd' },
  { value: 'unavailable', text: 'Niet beschikbaar' },
  { value: 'unsubscribed', text: 'Uitgeschreven' },
];

export const Salutation = [
  {
    value: 'mr',
    text: 'De heer',
  },
  {
    value: 'mrs',
    text: 'Mevrouw',
  },
  {
    value: 'none',
    text: 'Geen',
  }
];

export const Frequency = [
  {
    value: 'none',
    text: 'Eenmalig',
  },
  {
    value: '1pw',
    text: '1x per week',
  },
  {
    value: '1p2w',
    text: '1x per 2 weken',
  },
  {
    value: '1pm',
    text: '1x per maand',
  },
  {
    value: '1pk',
    text: '1x per kwartaal',
  }
];
