import { BehaviorSubject } from 'rxjs';

import { requestOptions, handleResponse } from '@/helpers';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser') || '{}'),
);

async function login(username: string, password: string): Promise<any> {
  const response = await fetch('api/auth/user/token', requestOptions.post({ email: username, password }));
  const user = await handleResponse(response);
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem('currentUser', JSON.stringify(user.data));
  currentUserSubject.next(user.data);
  return user;
}

function logout(): void {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue(): any {
    return currentUserSubject.value;
  },
};
